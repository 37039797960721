@import url("https://fonts.googleapis.com/css2?family$font-familyital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");

@import "./_variable";

// hero section
.herosection {
  background-color: $light_bg;
  .herosection-content {
    border-radius: 20px;
    font-size: 15px;
    background-color: #fff;
    .btnn {
      background-color: $light-voilet;
      color: #fff;
      border-radius: 20px;
      border: none;
    }
    .next-coding {
      color: $heading-text;
      border-radius: 20px;
      font-family: $font-text;
    }
  }

  .minute {
    color: $light-voilet;
    background-color: #dfd9fc;
    font-size: 13px;
    font-weight: 600;
    font-family: $font-headings;
  }
  .free-class {
    font-family: $font-headings;
    font-size: 60px;
    font-weight: 700;
    line-height: 1.3;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .access-technology {
    color: $light-voilet;
    font-size: 38px;
    font-weight: 600;
    font-family: $font-headings;
  }
  .learn-coding {
    color: $heading-text;
    font-size: 22px;
    font-weight: 500;
    font-family: $font-text;
  }
  .btn {
    background-color: $light-voilet;
    color: #fff;
    border-radius: 35px;
    font-size: 20px;
  }
  .rate {
    font-size: 28px;
    font-weight: 500;
    color: $heading-text;
  }
  h4 {
    font-size: 18px;
    font-weight: 500;
    font-family: $font-headings;
    span {
      font-weight: 700;
    }
  }
  .fa {
    color: #ffd700;
    font-size: 25px;
  }
}

// joinpage
.section-header {
  border-radius: 10px;
  background: linear-gradient(0, #f5f1fe 0%, #fff 100%);
  .join-head {
    font-size: 42px;
    line-height: 1.5;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-family: $font-headings;
  }
  .pink {
    font-size: 38px;
    line-height: 1.5;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-family: $font-headings;
  }
  h4 {
    font-size: 18px;
    font-family: $font-headings;
    color: $base-font-color;
  }
  .express-countdown {
    font-weight: bold;
    .count-down-value,
    .count-down-seperator,
    .count-down-unit {
      font-size: 20px;
      font-family: $font-family;
    }
  }
  .form-section {
    background-color: #fff;
    border-radius: 15px;
    .reserve {
      font-size: 19px;
      font-family: $font-text;
      font-weight: 500;
    }
    .form-label {
      font-size: 17px;
      color: $heading-text;
      font-weight: 500;
      font-family: $font-text;
    }
    .form-field {
      border-radius: 10px;
      font-size: 17px;
      font-family: $font-text;
    }
  }
  .btn {
    font-size: 20px;
    border-radius: 35px;
    background-color: $light-voilet;
    color: #fff;
  }
  .limited-slots {
    font-size: 19px;
    color: $base-font-color;
    font-weight: 500;
  }
  .companies-name {
    font-size: 18px;
    font-weight: 500;
    color: $heading-text;
    font-family: $font-headings;
    span {
      font-weight: 700;
    }
  }
}

// what you will learn
.whatUhWillLearn {
  .head-learn {
    font-size: 42px;
    font-weight: 700;
    font-family: $font-headings;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .learning-section {
    border-radius: 20px;
    min-height: 220px;
    .learn-para {
      font-size: 18px;
      color: $base-font-color;
      font-weight: 500;
      font-family: $font-text;
    }
  }
  h5 {
    font-family: $font-headings;
    font-weight: 700;
  }
}

// watch preview
.watchPreview {
  .watchpreview-head {
    font-size: 42px;
    font-weight: 700;
    font-family: $font-headings;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

// meet ur instructor
.meetInstructor {
  background-color: $light_bg;
  .instructor-head {
    font-size: 42px;
    font-weight: 700;
    font-family: $font-headings;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .instructor-para {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.8;
    color: $heading-text;
    font-family: $font-text;
  }
  .instructor-sub-para {
    font-size: 19px;
    font-weight: 500;
    line-height: 1.8;
    color: $heading-text;
    font-family: $font-text;
  }
  .students-say {
    font-size: 19px;
    color: $light-voilet;
    font-weight: 600;
    text-decoration: underline;
    font-family: $font-text;
  }
  .linkedin-link {
    font-size: 19px;
    font-weight: 600;
    text-decoration: underline;
    font-family: $font-text;
  }
}

// reviews
.reviews {
  // background-color: #ffc63a1a;
  .review-head {
    font-size: 42px;
    line-height: 1.25;
    font-weight: 700;
    font-family: $font-headings;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .review-para {
    font-size: 21px;
    font-weight: 500;
    color: $base-font-color;
    font-family: $font-text;
  }
  .all-review,
  .review-name-link {
    font-size: 21px;
    color: $light-voilet;
    font-weight: 600;
    text-decoration: underline;
    font-family: $font-text;
  }
  .review-people {
    border-radius: 20px;
  }
  .review-name-title {
    font-size: 23px;
    font-weight: 700;
    line-height: 1.5;
  }
  .review-of-people {
    font-size: 18px;
    line-height: 1.5;
    color: $heading-text;
    font-weight: 500;
  }
  h2,
  h5,
  h6 {
    font-family: $font-headings;
  }
  p {
    font-family: $font-text;
  }
}

// our mission
.mission {
  .ourMission {
    border-radius: 20px;
    background: linear-gradient(180deg, #cab5ff 0%, #f4efff 100%);
    .mission-head {
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-family: $font-headings;
    }
    .mission-sub-head {
      font-size: 36px;
      font-weight: 700;
      font-family: $font-headings;
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .women-percent {
      font-size: 18px;
      font-weight: 500;
      font-family: $font-headings;
      color: $heading-text;
    }
    .mission-para {
      font-size: 18px;
      font-weight: 500;
      color: $heading-text;
      font-family: $font-text;
    }
  }
  .women-enrolled {
    background-color: $light_bg;
    border-radius: 20px;
    .women-enrolled-head {
      font-weight: 700;
      font-size: 36px;
      font-family: $font-headings;
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .women-enrolled-para {
      color: $heading-text;
      font-size: 18px;
      font-weight: 500;
      font-family: $font-text;
    }
  }
}

// question
.question {
  border-radius: 20px;
  .question-head {
    font-size: 42px;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-family: $font-headings;
  }
  .question-subhead {
    color: $base-font-color;
    font-size: 22px;
    font-weight: 600;
    font-family: $font-headings;
    .reach-link {
      color: $light-voilet;
    }
  }
  .question-para {
    font-size: 18px;
    font-weight: 600;
    color: $base-font-color;
    font-family: $font-text;
  }
}

// join mailing list
.joinMailing {
  border-radius: 20px;
  .emojii {
    font-size: 62px;
  }
  .mailing-head {
    font-size: $head-font-size;
    font-weight: 700;
    color: $heading-text;
    font-family: $font-headings;
  }
  .mailing-para {
    font-family: $font-text;
    font-size: 20px;
    font-weight: 500;
    color: $base-font-color;
  }
  .form-field {
    border-radius: 10px;
    font-size: 20px;
  }
  .btn {
    background-color: $light-voilet;
    color: #fff;
    padding-left: 72px;
    padding-right: 72px;
    font-size: 17px;
  }
}

// Miscellaneous
.miscellaneous {
  background-color: $light_bg;
  .join-mailing {
    font-size: 24px;
    font-weight: 600;
    font-family: $font-headings;
    color: $heading-text;
  }
  .be-the-first {
    font-family: $font-text;
    font-size: 18px;
    font-weight: 400;
    color: $base-font-color;
  }
  .first-block {
    border-radius: 35px;
    background-color: #fff;
    font-size: 18px;
    input {
      font-family: $font-text;
      border: none;
      border-radius: 35px;
    }
    .btn {
      background-color: $light-voilet;
      color: #fff;
      border-radius: 35px;
    }
  }
  .instructor {
    font-size: 23px;
    font-weight: 700;
    font-family: $font-headings;
  }
  .akshay {
    color: $light-voilet;
    font-family: $font-headings;
    font-weight: 700;
  }
  .list {
    font-size: 18px;
    a {
      font-family: $font-text;
      color: $heading-text;
    }
  }
}

// footer
.footer {
  background-color: #f5f1fe;
  .instagram {
    font-size: 22px;
    font-weight: 700;
    color: #212529;
    .a {
      color: #212529;
      font-family: "Source Code Pro", monospace;
    }
  }
  .privacy {
    font-family: "Source Code Pro", monospace;
    font-weight: 500;
    font-size: 19px;
    a {
      color: #212529;
    }
  }
}

// mobile responsive
@media (max-width: 500px) {
  $head-mobile-font-size: 22px;
  // header
  .header {
    .coding-workshop,
    .free-code-class,
    .dropdown-toggle,
    .btn,
    .btn-login {
      font-size: 12px;
    }
  }
  // navbar
  .navbar {
    .btn,
    .btn-login {
      font-size: 12px;
    }
    .dropdown {
      .dropdown-toggle,
      .category-dropdown {
        font-size: 12px;
        padding: 0px;
      }
      .dropdown-menu {
        display: none;
      }
    }
  }

  // herosection
  .herosection {
    .minute {
      font-size: 7px;
    }
    .free-class {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.2;
    }
    .access-technology {
      font-size: 20px;
      font-weight: 700;
    }
    .learn-coding {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
    }
    h4 {
      font-size: 12px;
    }
    .btn {
      font-size: 14px;
    }
    .herosection-content {
      display: none;
    }
    .rate {
      font-size: 20px;
    }
  }

  //   joinpage
  .section-header {
    .join-head,
    .pink {
      font-size: $head-mobile-font-size;
      line-height: 1.3;
    }
    .express-countdown {
      .count-down-value,
      .count-down-seperator,
      .count-down-unit {
        font-size: 11px;
      }
    }
    h4 {
      font-size: 13px;
    }
    .form-section {
      .reserve {
        font-size: 14px;
      }
      .form-label,
      .form-field {
        font-size: 12px;
      }
      .btn {
        font-size: 14px;
      }
      .limited-slots {
        font-size: 12px;
      }
    }
    .companies-name {
      font-size: 13px;
    }
  }
  //   what uh will learn
  .whatUhWillLearn {
    .head-learn {
      font-size: $head-mobile-font-size;
    }
    .learning-section {
      min-height: auto;
      .learn-para {
        font-size: 12px;
      }
      .emojii {
        font-size: 20px;
      }
    }
    h5 {
      font-size: 12px;
    }
  }

  //   watch preview
  .watchPreview {
    .watchpreview-head {
      font-size: $head-mobile-font-size;
    }
    iframe {
      height: 200px;
    }
  }

  // meet ur instructor
  .meetInstructor {
    .instructor-head {
      font-size: $head-mobile-font-size;
      line-height: 1.5;
      text-align: center;
    }
    .instructor-para,
    .instructor-sub-para {
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
    }
    .students-say,
    .linkedin-link {
      font-size: 15px;
      text-align: center;
    }
  }

  //   review
  .reviews {
    .review-head {
      font-size: $head-mobile-font-size;
    }
    .review-para {
      font-size: 16px;
    }
    .all-review {
      font-size: 16px;
    }
    .review-content {
      //   display: none;
      .review-people {
        .review-name-link,
        h5 {
          font-size: 14px;
        }
        h6 {
          font-size: 15px;
        }
        .review-name-title {
          font-size: 18px;
        }
        .review-of-people {
          font-size: 13px;
        }
      }
    }
  }

  //   our mission
  .mission {
    .ourMission {
      .mission-head {
        font-size: 14px;
      }
      .mission-sub-head {
        font-size: $head-mobile-font-size;
      }
      .women-percent {
        font-size: 17px;
      }
      .mission-para {
        font-size: 15px;
      }
    }
    .women-enrolled {
      .women-enrolled-head {
        font-size: 22px;
      }
      .women-enrolled-para {
        font-size: 15px;
      }
    }
  }

  //   question
  .question {
    .question-head {
      font-size: $head-mobile-font-size;
    }
    .question-subhead {
      font-size: 15px;
    }
    .question-para {
      font-size: 16px;
    }
  }

  // join mailing list
  .joinMailing {
    .emojii {
      font-size: 32px;
    }
    .mailing-head {
      font-size: $head-mobile-font-size;
    }
    .mailing-para {
      font-size: 16px;
    }
    .form-field {
      font-size: 14px;
    }
    .btn {
      font-size: 13px;
    }
  }

  // miscellaneous
  .miscellaneous {
    .join-mailing {
      font-size: 20px;
      text-align: center;
    }
    .be-the-first {
      font-size: 15px;
      text-align: center;
    }
    .first-block {
      input {
        font-size: 10px;
      }
      .btn {
        font-size: 10px;
      }
    }
    .instructor {
      text-align: center;
      font-size: 18px;
    }
    .akshay {
      text-align: center;
    }
    .get-started,
    .coding-tools,
    .about-us {
      // display: none;
      .list {
        font-size: 14px;
        text-align: center;
      }
    }
  }

  // footer
  .footer {
    .instagram,
    .privacy {
      font-size: 11px;
    }
    .privacy {
      font-size: 14px;
    }
  }
}
